import Dashboard from "../pages/online-orders/OnlineOrders";
import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import ViewOrder from "../pages/online-orders/ViewOrder";

function OnlineOrdersRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Dashboard />} />
      <Route path={"/details"} element={<ViewOrder />} />
    </Routes>
  );
}

export default OnlineOrdersRouter;

import { useState } from "react";
import useBusinesses from "../../hooks/api/useBusinesses";
import { Form, FormInput, Submit } from "../form";
import ImagePicker from "../ImagePicker";
import { MenuCategorySchema } from "../../validators/icart";

function MenuCategory(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { addMenuCategory, isLoading } = useBusinesses();
  return (
    <div>
      <h2>New Menu Category</h2>
      <br />
      <Form
        validationSchema={MenuCategorySchema}
        onSubmit={(v) =>
          addMenuCategory(
            {
              name: v.name,
            },
            selectedImage
          )
        }
        initialValues={{ name: "" }}
      >
        <FormInput name="name" placeholder="Name" />
        <ImagePicker onSelect={setSelectedImage} placeholder="Upload Image" />
        <br />
        <Submit loading={isLoading} className="btn-submit" title="Save" />
      </Form>
    </div>
  );
}

export default MenuCategory;

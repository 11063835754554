import { useState } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";

function useOnlineOrders(props) {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const { isLoading, get } = useAPI();

  const getOrders = async (limit = 10000, pageNumber = 1) => {
    const { error, data } = await get(
      urls.admin.onlineOrders.baseUrl +
        `?limit=${limit}&pageNumber=${pageNumber}`
    );
    if (error) return;
    setOrders(data.data.orders);
  };
  const getOrder = async () => {};

  return {
    isLoading,
    orders,
    order,
    getOrder,
    getOrders,
  };
}

export default useOnlineOrders;

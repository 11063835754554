import AccountPreview from "../AccountPreview";
import useAccounts from "../../hooks/api/useAccounts";
import urls from "../../api/urls";
import { useEffect } from "react";
import Table from "../table/Table";
import Loader from "../Loader";
import { useLocation } from "react-router-dom";
import useBusinesses from "../../hooks/api/useBusinesses";

function AddOperatorToKiosk({}) {
  const {
    state: { data },
  } = useLocation();
  const { accounts, getAccounts, isLoading } = useAccounts({
    url: urls.admin.accounts,
  });
  const { updateKioskOperators, isLoading: loading } = useBusinesses();

  useEffect(() => {
    getAccounts(1, 200, "operator");
  }, []);

  return (
    <div>
      <Loader loading={isLoading || loading} />
      <Table
        data={accounts.filter((a) => !a.operatingKiosk)}
        head={[
          ...head,
          {
            title: "-",
            target: "_id",
            render: (id) => (
              <button
                onClick={() =>
                  updateKioskOperators({
                    id,
                    action: "add",
                    kiosk: data.kiosk,
                  })
                }
                className="btn btn-action true"
              >
                Add
              </button>
            ),
          },
        ]}
        title="Operators"
      />
    </div>
  );
}

export default AddOperatorToKiosk;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Operator Name",
    target: ["imageUrl", "firstName", "lastName"],
    render: (value) => (
      <AccountPreview
        user={{
          imageUrl: value[0],
          firstName: value[1],
          lastName: value[2],
        }}
      />
    ),
  },
  {
    title: "Email",
    target: "email",
  },
  {
    title: "Phone Number",
    target: "phoneNumber",
  },
];

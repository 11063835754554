import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormInput, Submit } from "../../../components/form";
import ImagePicker from "../../../components/ImagePicker";
import useBusinesses from "../../../hooks/api/useBusinesses";
import { conceptSchema, MenuCategorySchema } from "../../../validators/icart";
import CheckBox from "../../CheckBox";

export default function ViewCategory() {
  const {
    state: { data },
  } = useLocation();
  const [active, setActive] = useState(data.active);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const { isLoading, updateCategory } = useBusinesses();

  return (
    <div>
      <h2>View Category</h2>
      <br />
      <Form
        validationSchema={MenuCategorySchema}
        onSubmit={(v) => {
          updateCategory(data._id, { ...v, active }, selectedImage);
        }}
        initialValues={{
          name: data.name,
        }}
      >
        <FormInput name="name" placeholder="Name" />

        <ImagePicker
          initial={data.imageUrl}
          onSelect={setSelectedImage}
          placeholder="Upload Image"
        />
        <br />
        <div className="flex align-center">
          <CheckBox onChange={setActive} value={active} />
          <span style={{ marginLeft: 5 }}>Active</span>
        </div>
        <br />
        <Submit
          loading={isLoading}
          disabled={isLoading}
          className="btn-submit"
          title="Save and Update"
        />
      </Form>
    </div>
  );
}

import { useNavigate, useLocation } from "react-router-dom";
import Table from "../../table/Table";
import { links } from "../../../utils/routes";
import { models } from "../../../utils/vars";
import svg from "../../../utils/svg";
import AccountPreview from "../../AccountPreview";
import { currencyFormatter } from "../../../utils/func";

function WalletsList() {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  const head = [
    {
      title: "#",
      target: "#",
      className: "count",
    },
    {
      title: "User",
      target: ["account.profileImage", "account.firstName", "account.lastName"],
      render: (value) => (
        <AccountPreview
          user={{
            profileImage: value[0],
            firstName: value[1],
            lastName: value[2],
          }}
        />
      ),
    },
    {
      title: "Balance",
      target: "balance",
      render: (v) => currencyFormatter(v || 0),
    },
    {
      title: "Status",
      target: "status",
      render: (value) => (
        <span className={`status ${value}`}>{value.toUpperCase()}</span>
      ),
    },
    {
      title: "Actions",
      target: "_id",
      render: (id) => (
        <button
          className="action flex align-center"
          onClick={() =>
            navigate(links.wallets.view(models.wallet, id), {
              state: { data: data.filter((a) => a._id === id)[0] },
            })
          }
        >
          {svg.eye()} <span className="f700">VIEW</span>
        </button>
      ),
    },
  ];

  return (
    <div>
      <Table data={data} head={head} title="Transactions" />
    </div>
  );
}

export default WalletsList;

import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import Dashboard from "../pages/sales/Dashboard";

function SalesRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Dashboard />} />
    </Routes>
  );
}

export default SalesRouter;

import { useEffect } from "react";
import AccountPreview from "../../components/AccountPreview";
import Table from "../../components/table/Table";
import useMessages from "../../hooks/api/useMessages";
import { formatDate } from "../../utils/func";

function Dashboard(props) {
  const { messages, getMessages, isLoading } = useMessages();

  useEffect(() => {
    getMessages(1, 10000);
  }, []);
  return (
    <div>
      <Table data={messages} head={head} title="Messages" />
    </div>
  );
}

export default Dashboard;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "User",
    target: ["profileImage", "firstName", "lastName"],
    render: (value) => (
      <AccountPreview
        user={{
          profileImage: value[0],
          firstName: value[1],
          lastName: value[2],
        }}
      />
    ),
  },
  {
    title: "Email",
    target: "email",
    render: (v) => <span style={{ textAlign: "left" }}>{v}</span>,
  },
  {
    title: "Phone Number",
    target: "phoneNumber",
    render: (v) => v,
  },
  {
    title: "Message",
    target: "message",
    render: (v) => v,
  },
  {
    title: "Date",
    target: "createdAt",
    render: (v) => formatDate(v),
  },
];

import useLocations from "../../hooks/api/useLocations";
import Table from "../../components/table/Table";
import { useEffect } from "react";
import svg from "../../utils/svg";
import CreateLinks from "../../components/CreateLinks";
import { links } from "../../utils/routes";
import { models } from "../../utils/vars";
import useBusinesses from "../../hooks/api/useBusinesses";
import { useNavigate } from "react-router-dom";
import AccountPreview from "../../components/AccountPreview";
import { currencyFormatter } from "../../utils/func";
import RangePicker from "../../components/RangePicker";

const Card = ({ title, value }) => (
  <div className="card acct-info flex justify-center align-center flex-column">
    <span className="q f400 t-default">{title}</span>
    <br />
    <h2 className="ttitle montserrat t-blue f600">{value}</h2>
  </div>
);

function Wallets(props) {
  const {
    getRecentTransactions,
    getWallets,
    wallets,
    recentTransactions,
    isLoading,
  } = useBusinesses();
  const navigate = useNavigate();
  useEffect(() => {
    getRecentTransactions();
    getWallets();

    // eslint-disable-next-line
  }, []);
  const refresh = (startDate, endDate, filter) => {
    getRecentTransactions(1, 20, startDate, endDate, filter);
  };

  const transactionsHead = [
    {
      title: "#",
      target: "#",
      className: "count",
    },
    {
      title: "User",
      target: [
        "wallet.account.profileImage",
        "wallet.account.firstName",
        "wallet.account.lastName",
      ],
      render: (value) => (
        <AccountPreview
          user={{
            profileImage: value[0],
            firstName: value[1],
            lastName: value[2],
          }}
        />
      ),
    },
    {
      title: "Title",
      target: "title",
    },
    {
      title: "Amount",
      target: "amount",
      render: (v) => currencyFormatter(v || 0),
    },
    {
      title: "Status",
      target: "status",
      render: (value) => (
        <span className={`status ${value}`}>{value.toUpperCase()}</span>
      ),
    },
    {
      title: "Actions",
      target: "_id",
      render: (id) => (
        <button
          className="action flex align-center"
          onClick={() =>
            navigate(links.wallets.view(models.transaction, id), {
              state: {
                data: recentTransactions.filter((k) => k._id === id)[0],
              },
            })
          }
        >
          {svg.eye()} <span className="f700">VIEW</span>
        </button>
      ),
    },
  ];

  const walletshead = [
    {
      title: "#",
      target: "#",
      className: "count",
    },
    {
      title: "User",
      target: ["account.profileImage", "account.firstName", "account.lastName"],
      render: (value) => (
        <AccountPreview
          user={{
            profileImage: value[0],
            firstName: value[1],
            lastName: value[2],
          }}
        />
      ),
    },
    {
      title: "User Role",
      target: "account.role",
    },
    {
      title: "Balance",
      target: "balance",
      render: (v) => currencyFormatter(v || 0),
    },
    {
      title: "Status",
      target: "status",
      render: (value) => (
        <span className={`status ${value}`}>{value.toUpperCase()}</span>
      ),
    },
    {
      title: "Actions",
      target: "_id",
      render: (id) => (
        <button
          className="action flex align-center"
          onClick={() =>
            navigate(links.wallets.view(models.wallet, id), {
              state: { data: wallets.filter((a) => a._id === id)[0] },
            })
          }
        >
          {svg.eye()} <span className="f700">VIEW</span>
        </button>
      ),
    },
  ];

  return (
    <div>
      <br />
      <br />
      <RangePicker
        loading={isLoading}
        filterOptions={Object.values({
          pending: "pending",
          successful: "successful",
        }).map((s) => ({
          label: s,
          value: s,
        }))}
        onClick={refresh}
      />
      <div className="flex justify-between align-center gap-3">
        <Card title="Transactions" value={recentTransactions.length} />
        <Card title="Wallets" value={wallets.length} />
        <Card
          title="Balance"
          value={currencyFormatter(
            wallets.reduce((p, c) => p + c.balance, 0).toFixed(0)
          )}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <Table
        title="Recent Transactions"
        btn={{
          title: "View all",
          className: "btn-view",
          onClick: () =>
            navigate(links.wallets.list(models.transaction), {
              state: { data: recentTransactions },
            }),
        }}
        head={transactionsHead}
        data={recentTransactions.slice(0, 10)}
      />
      <br />
      <br />
      <Table
        title="Wallets"
        btn={{
          title: "View all",
          className: "btn-view",

          onClick: () =>
            navigate(links.wallets.list(models.wallet), {
              state: { data: wallets },
            }),
        }}
        head={walletshead}
        data={wallets}
      />
    </div>
  );
}

export default Wallets;

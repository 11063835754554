import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import Operators from "../pages/operators/Operators";

function OperatorsRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Operators />} />
    </Routes>
  );
}

export default OperatorsRouter;

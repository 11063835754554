import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormInput, Submit } from "../../../components/form";
import ImagePicker from "../../../components/ImagePicker";
import useBusinesses from "../../../hooks/api/useBusinesses";
import useLocations from "../../../hooks/api/useLocations";
import useOperations from "../../../hooks/api/useOperations";
import { kioskSchema } from "../../../validators/icart";
import Checkbox from "../../CheckBox";
import FormSelect from "../../form/FormSelect";
import Image from "../../Image";
import Select from "../../Select";
import { statuses } from "../../../utils/vars";
import Table from "../../table/Table";
import AccountPreview from "../../AccountPreview";
import { links } from "../../../utils/routes";

export default function ViewKiosk() {
  const {
    state: { data },
  } = useLocation();
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLocation, setselectedLocation] = useState(data.location);
  const { getLocation, locations } = useLocations();
  const [isActive, setIsActive] = useState(data.active);
  const [receiveOnlineOrder, setRecieveOnlineOrder] = useState(
    data.receiveOnlineOrder
  );
  const { isLoading: citiesLoading, getCities, cities } = useOperations();
  const {
    isLoading,
    updateKiosk,
    updateKioskOperators,
    isLoading: loading,
  } = useBusinesses();
  const navigate = useNavigate();

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (selectedCity) getLocation(selectedCity, 100, 1, statuses.active);
  }, [selectedCity]);

  return (
    <div>
      <h2>View Kiosk</h2>
      <br />
      <Form
        validationSchema={kioskSchema}
        onSubmit={(d) => {
          updateKiosk(
            {
              active: isActive,
              receiveOnlineOrder,
              location: d.location,
              id: d.id,
              phoneNumber: d.phoneNumber,
            },
            data._id,
            selectedImage
          );
        }}
        initialValues={{
          id: data.id,
          location: data.location?._id,
          phoneNumber: data.phoneNumber,
        }}
      >
        <FormInput name="id" placeholder="Kiosk ID" />
        <div className="inputs">
          <Select
            onSelect={setSelectedCity}
            loading={citiesLoading}
            options={cities.map((c) => ({
              value: c._id,
              label: `${c.name} - ${c.country}`,
            }))}
            placeholder="Select City"
          />
          <FormSelect
            name={"location"}
            options={locations.map((c) => ({
              value: c._id,
              label: c.label,
            }))}
            placeholder="Kiosk Location"
          />
        </div>
        <ImagePicker
          initial={data.imageUrl}
          onSelect={setSelectedImage}
          placeholder="Upload Image"
        />
        <br />
        <FormInput name="phoneNumber" placeholder="Phone Number" />
        <br />
        <div className="flex">
          <label>Active</label>
          <Checkbox onChange={setIsActive} value={isActive} />
          <br />
          <br />
        </div>
        <div className="flex">
          <label>Recieve Online Order</label>
          <Checkbox
            onChange={setRecieveOnlineOrder}
            value={receiveOnlineOrder}
          />
          <br />
          <br />
        </div>
        <Submit
          loading={isLoading}
          disabled={isLoading}
          className="btn-submit"
          title="Save and Update"
        />
      </Form>
      <br />
      <Table
        btn={{
          title: "Add",
          className: "btn-view",
          onClick: () =>
            navigate(links.icartsAndLocations.operators, {
              state: { data: { kiosk: data._id, operators: data.operators } },
            }),
        }}
        title="Operators"
        data={data.operators}
        head={[
          ...operatorsHead,
          {
            title: "-",
            target: "_id",
            render: (id) => (
              <button
                onClick={() =>
                  updateKioskOperators({
                    id,
                    action: "remove",
                    kiosk: data._id,
                  })
                }
                className="btn btn-action false"
              >
                Remove
              </button>
            ),
          },
        ]}
      />
      <div className="card img-card">
        <Image src={data.imageUrl} />
      </div>
    </div>
  );
}

const operatorsHead = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Name",
    target: ["profileImage", "firstName", "lastName"],
    render: (value) => (
      <AccountPreview
        user={{
          profileImage: value[0],
          firstName: value[1],
          lastName: value[2],
        }}
      />
    ),
  },
  {
    title: "Email",
    target: "email",
  },
  {
    title: "Phone Number",
    target: "phoneNumber",
  },
];

import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import Wallets from "../pages/wallets/Wallets";
import List from "../pages/wallets/List";
import View from "../pages/wallets/View";

function WalletsRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Wallets />} />
      <Route path={paths.list} element={<List />} />
      <Route path={paths.view} element={<View />} />
    </Routes>
  );
}

export default WalletsRouter;

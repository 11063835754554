import Dashboard from "../pages/businesses/Dashboard";
import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import PreOrders from "../pages/pre-orders/Index";
import PreOrdersList from "../pages/pre-orders/List";

function PreOrdersRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<PreOrders />} />
      <Route path={paths.list} element={<PreOrdersList />} />
    </Routes>
  );
}

export default PreOrdersRouter;

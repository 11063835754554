import C404 from "../../components/404";
import Admins from "../../components/accounts/list/Admins";
import Departments from "../../components/accounts/list/Departments";
import Operators from "../../components/accounts/list/Operators";
import Staffs from "../../components/accounts/list/Staffs";
import Suppliers from "../../components/accounts/list/Suppliers";
import Users from "../../components/accounts/list/Users";
import useQuery from "../../hooks/useQuery";
import { acctTypes } from "../../utils/vars";

function AccountsList(props) {
  const query = useQuery();
  const type = query.get("type");
  if (!type || !acctTypes[type]) return <C404 />;

  return type === acctTypes.admin ? (
    <Admins />
  ) : type === acctTypes.department ? (
    <Departments />
  ) : type === acctTypes.operator ? (
    <Operators />
  ) : type === acctTypes.user ? (
    <Users />
  ) : type === acctTypes.supplier ? (
    <Suppliers />
  ) : type === acctTypes.creator ? (
    <Suppliers />
  ) : (
    <Staffs />
  );
}

export default AccountsList;

import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import Settings from "../pages/settings/Settings";

function SettingsRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Settings />} />
    </Routes>
  );
}

export default SettingsRouter;

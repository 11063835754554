import { useNavigate, useLocation } from "react-router-dom";
import Table from "../../table/Table";
import { links } from "../../../utils/routes";
import { models } from "../../../utils/vars";
import svg from "../../../utils/svg";
import AccountPreview from "../../AccountPreview";
import { currencyFormatter } from "../../../utils/func";

function TransactionsList({ initialLimit }) {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  const head = [
    {
      title: "#",
      target: "#",
      className: "count",
    },
    {
      title: "User",
      target: [
        "wallet.account.profileImage",
        "wallet.account.firstName",
        "wallet.account.lastName",
      ],
      render: (value) => (
        <AccountPreview
          user={{
            profileImage: value[0],
            firstName: value[1],
            lastName: value[2],
          }}
        />
      ),
    },
    {
      title: "Title",
      target: "title",
    },
    {
      title: "Amount",
      target: "amount",
      render: (v) => currencyFormatter(v || 0),
    },
    {
      title: "Reference",
      target: "reference",
    },
    {
      title: "Method",
      target: "payMethod",
    },
    {
      title: "Description",
      target: "description",
    },
    {
      title: "Status",
      target: "status",
      render: (value) => (
        <span className={`status ${value}`}>{value.toUpperCase()}</span>
      ),
    },
    {
      title: "Actions",
      target: "_id",
      render: (id) => (
        <button
          className="action flex align-center"
          onClick={() =>
            navigate(links.icartsAndLocations.view(models.kiosk, id), {
              state: {
                data: data.filter((k) => k._id === id)[0],
              },
            })
          }
        >
          {svg.eye()} <span className="f700">VIEW</span>
        </button>
      ),
    },
  ];

  return (
    <div>
      <Table
        btn={
          initialLimit && {
            title: "View All",
            className: "btn-view",
            onClick: () => navigate(links.businesses.list(models.transactions)),
          }
        }
        data={data}
        head={head}
        title="Transactions"
      />
    </div>
  );
}

export default TransactionsList;

import { useState } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";
import { toast } from "react-toastify";

function useSettings() {
  const [settings, setSettings] = useState([]);
  const [partners, setPartners] = useState([]);
  const [riders, setRiders] = useState([]);

  const { get, isLoading, patch, post } = useAPI();

  const getSettings = async () => {
    const { error, data } = await get(urls.admin.settings.baseUrl);
    if (error) return;
    setSettings(data.data.settings);
  };

  const getPartners = async () => {
    const { error, data } = await get(urls.admin.settings.partners);
    if (error) return;
    setPartners(data.data.partners);
  };

  const getRiders = async () => {
    const { error, data } = await get(urls.admin.settings.riders);
    if (error) return;
    setRiders(data.data.riders);
  };

  const payDeliveries = async (fdata, cb) => {
    const { error, data } = await post(urls.admin.settings.payRides, fdata);
    if (error) return;
    toast.success(data.message);
    if (typeof cb === "function") cb();
  };

  const updateSettings = async (fdata) => {
    const { error, data } = await patch(urls.admin.settings.baseUrl, fdata);
    if (error) return;
    toast.success(data.message);
    getSettings();
  };

  return {
    isLoading,
    getSettings,
    updateSettings,
    getPartners,
    getRiders,
    settings,
    payDeliveries,
    partners,
    riders,
  };
}

export default useSettings;

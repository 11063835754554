import { acctTypes } from "../../../utils/vars";
import { useNavigate } from "react-router-dom";
import { links } from "../../../utils/routes";
import Table from "../../table/Table";
import svg from "../../../utils/svg";
import AccountPreview from "../../AccountPreview";
import useAccounts from "../../../hooks/api/useAccounts";
import urls from "../../../api/urls";
import { useEffect } from "react";

function Operators({ initialLimit }) {
  const navigate = useNavigate();
  const { accounts, getAccounts } = useAccounts({ url: urls.admin.accounts });

  useEffect(() => {
    getAccounts(1, 200, "operator");
  }, []);

  return (
    <div>
      <Table
        btn={
          initialLimit && {
            title: "View All",
            className: "btn-view",
            onClick: () => navigate(links.accounts.list(acctTypes.operator)),
          }
        }
        data={accounts}
        head={[
          ...head,
          {
            title: "Actions",
            target: "id",
            render: (id) => (
              <button
                onClick={() =>
                  navigate(links.accounts.view(acctTypes.operator, id), {
                    state: { data: accounts.filter((a) => a.id == id)[0] },
                  })
                }
                className="action flex align-center"
              >
                {svg.eye()} <span className="f700">VIEW</span>
              </button>
            ),
          },
        ]}
        title="Operators"
      />
    </div>
  );
}

export default Operators;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Operator Name",
    target: ["imageUrl", "firstName", "lastName"],
    render: (value) => (
      <AccountPreview
        user={{
          imageUrl: value[0],
          firstName: value[1],
          lastName: value[2],
        }}
      />
    ),
  },
  {
    title: "iCart",
    target: "operatingKiosk",
  },
  // {
  //   title: "Location",
  //   target: "location",
  // },
  // {
  //   title: "Operator ID",
  //   target: "id",
  // },
  {
    title: "Active",
    target: "isActive",
    render: (value) => (
      <span className={`status ${value}`}>
        {value.toString().toUpperCase()}
      </span>
    ),
  },
];

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { capitalizeFirstLetter, currencyFormatter } from "../../utils/func";
import svg from "../../utils/svg";
import Image from "../../components/Image";
import Button from "../../components/Button";
// import useGeoLocation from "../../hooks/useLcoation";
import { toast } from "react-toastify";
// import Reciept from "../../components/Reciept";

function ViewOrder(props) {
  //   const [showOrder, setShowOrder] = useState(false);
  const [image, setImage] = useState(null);
  const {
    state: { data },
  } = useLocation();
  //   const { position } = useGeoLocation();
  const isLoading = false;
  return (
    <div>
      {/* {showOrder && (
        <Reciept handleClose={() => setShowOrder(false)} order={order} />
      )} */}
      {image && (
        <div className="menu-image flex justify-center align-center">
          <Image src={image} />
          <button onClick={() => setImage(null)} className="status false">
            close
          </button>
        </div>
      )}
      <h2>Order: {data.id}</h2>
      <br />
      <div className="user-profile-head flex align-center">
        <Image
          style={{ width: 100, height: 100 }}
          crossOrigin="anonymous"
          src={
            "https://thumbs.dreamstime.com/z/default-avatar-profile-icon-social-media-user-vector-default-avatar-profile-icon-social-media-user-vector-portrait-176194876.jpg?w=768"
          }
          alt={data.customerInformation.name}
        />
        <div>
          <h3 className="t-blue">{`${data.customerInformation.name} `}</h3>
          <span className="t-grey-3 f500">
            <a href={`mailto:${data.customerInformation.email}`}>
              {data.customerInformation.email}
            </a>{" "}
            |{" "}
            <a href={`tel:${data.customerInformation.phoneNumber}`}>
              {data.customerInformation.phoneNumber}
            </a>
          </span>
          <br />
          <span
            style={{ marginRight: 10 }}
            className={`status ${data.paymentCompleted}`}
          >
            {data.paymentCompleted ? "PAID" : "NOT PAID"}
          </span>
          <span className={`status ${data.status}`}>
            {data.status?.toUpperCase()}
          </span>
        </div>
      </div>
      <br />
      <div className="card" style={{ padding: 15 }}>
        <h4 className="t-text">Delivery To:</h4>
        <a
          href={`https://www.google.com/maps/?q=${data.deliveryTo.lat},${data.deliveryTo.lng}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.deliveryTo.address}
        </a>
        <br />
        <br />
        <h4 className="t-text">Customer Phone Number:</h4>
        <a
          href={`tel:${data.customerInformation.phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.customerInformation.phoneNumber}
        </a>
        <br />
        <br />
        <h4 className="t-text">Rider Information:</h4>
        <span>
          {data.rider?.firstName} {data.rider?.lastName} (
          <a
            href={`tel:${data.rider?.phoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.rider?.phoneNumber}
          </a>
          )
        </span>
        <br />
        <br />
        <h4 className="t-text">Delivery cost:</h4>
        <span>{currencyFormatter(data.deliveryFee)}</span>
        <br />
        <br />
        <h4 className="t-text">Total cost:</h4>
        <span>{currencyFormatter(data.costTotal)}</span>
        <br />
        {!data.paymentCompleted && (
          <Button
            disabled
            style={{ marginLeft: "auto" }}
            loading={isLoading}
            className="btn-submit"
            title="Confirm Payment"
          />
        )}
        <br />
      </div>
      <br />
      <Table
        loading={isLoading}
        title="Order Items"
        data={data.items}
        head={[
          ...head,
          {
            title: "Actions",
            target: "item.imageUrl",
            render: (image) => (
              <button
                className="action flex align-center"
                onClick={() => setImage(image)}
              >
                {svg.eye()} <span className="f700">VIEW</span>
              </button>
            ),
          },
        ]}
      />
      <br />
      {data.status === "pending" && (
        <Button
          disabled
          //   onClick={() => {
          //     if (!position?.latitude)
          //       return toast.error("Unable to get your location");
          //     acceptOrder(data._id, data.id, async () => {
          //       await addSale({
          //         items: data.items.map((i) => ({
          //           id: i.item._id,
          //           quantity: i.quantity,
          //         })),
          //         position,
          //       });
          //     });
          //   }}
          loading={isLoading}
          className="btn-submit"
          title="Accept Order"
        />
      )}
      {data.status === "processing" && (
        <Button
          disabled
          //   onClick={() => completeOrder(data._id, data.id)}
          loading={isLoading}
          className="btn-submit"
          title="Complete Order"
        />
      )}
      {/* <Button
        style={{ marginLeft: "auto" }}
        onClick={() => {
          setShowOrder(true);
        }}
        loading={isLoading}
        className="btn-submit"
        title="Show Reciept"
      /> */}

      {/* <br />
      <Table
        loading={isLoading}
        title="Customer & Delivery Information"
        data={[order]}
        head={head2}
      /> */}
    </div>
  );
}

export default ViewOrder;

const head = [
  {
    title: "#",
    target: "#",
  },
  {
    title: "Name",
    target: "item.name",
    render: capitalizeFirstLetter,
  },
  {
    title: "Quantity",
    target: "quantity",
    render: (v) => currencyFormatter(v, ""),
  },
  {
    title: "Total",
    target: ["item.price", "quantity"],
    render: (v) => currencyFormatter(v[0] * v[1]),
  },
  //   {
  //     name: "All",
  //     target: "*",
  //     render: JSON.stringify,
  //   },
];

// const head2 = [
//   {
//     title: "Customer Name",
//     target: "customerInformation.name",
//   },
//   {
//     title: "Customer Phone",
//     target: "customerInformation.phoneNumber",
//     render: (p) => <a href={`tel:${p}`}>{p}</a>,
//   },
//   {
//     title: "Paid",
//     target: "paymentCompleted",
//     render: (value) => (
//       <span className={`status ${value}`}>
//         {value.toString().toUpperCase()}
//       </span>
//     ),
//   },
//   {
//     title: "Delivery To",
//     target: "deliveryTo",
//     render: (v) => (
//       <a
//         href={`https://www.google.com/maps/?q=${v.lat},${v.lng}`}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         {v.address}
//       </a>
//     ),
//   },
// ];

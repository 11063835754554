import { useEffect, useState } from "react";
import useOnlineOrders from "../../hooks/api/useOnlineOrders";
import { capitalizeFirstLetter, formatDate } from "../../utils/func";
import Table from "../../components/table/Table";
import { useNavigate } from "react-router-dom";
import svg from "../../utils/svg";
import useBusinesses from "../../hooks/api/useBusinesses";
import Select from "../../components/Select";

const types = {
  pending: "pending",
  canceled: "canceled",
  completed: "completed",
  delivered: "delivered",
  processing: "processing",
};

const getCount = (orders, status) =>
  orders.filter((o) => o.status === status).length;

function Dashboard(props) {
  const [orderStat, setOrderStat] = useState({
    pending: 0,
    completed: 0,
    delivered: 0,
    processing: 0,
  });
  const [orderType, setOrderType] = useState(types.pending);
  const { getOrders, orders, isLoading } = useOnlineOrders();
  const { kiosks, getKiosks, isLoading: isLoading2 } = useBusinesses();
  const [kiosk, setKiosk] = useState(null);
  const navigate = useNavigate();

  const orderData = kiosk ? orders.filter((_) => _.kiosk === kiosk) : orders;

  useEffect(() => {
    getOrders();
    getKiosks();
  }, []);

  return (
    <div>
      <br />
      <br />
      <Select
        placeholder="iCart"
        initialValue={kiosk}
        options={kiosks?.map((_) => ({
          value: _._id,
          label: `${_.id}(${_.location.label})`,
        }))}
        onSelect={setKiosk}
      />
      <div
        style={{ cursor: "pointer" }}
        className="flex align-center links-create"
      >
        <div
          onClick={() => setOrderType(types.pending)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Pending Order</span>
          <h3>{getCount(orderData, types.pending)}</h3>
        </div>
        <div
          onClick={() => setOrderType(types.processing)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Processing Order</span>
          <h3>{getCount(orderData, types.processing)}</h3>
        </div>
        <div
          onClick={() => setOrderType(types.completed)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Completed Orders</span>
          <h3>{getCount(orderData, types.completed)}</h3>
        </div>
        <div
          onClick={() => setOrderType(types.delivered)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Delivered Orders</span>
          <h3>{getCount(orderData, types.delivered)}</h3>
        </div>
        <div
          onClick={() => setOrderType(types.canceled)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Canceled Orders</span>
          <h3>{getCount(orderData, types.canceled)}</h3>
        </div>
      </div>
      <br />
      <br />
      <Table
        showSearch={true}
        loading={isLoading || isLoading2}
        title={`${capitalizeFirstLetter(orderType)} Orders`}
        head={[
          ...head,
          {
            title: "Actions",
            target: "_id",
            render: (id) => (
              <button
                className="action flex align-center"
                onClick={() =>
                  navigate("/online-orders/details", {
                    state: { data: orders.filter((k) => k._id == id)[0] },
                  })
                }
              >
                {svg.eye()} <span className="f700">VIEW</span>
              </button>
            ),
          },
        ]}
        data={orderData.filter((o) => o.status === orderType)}
      />
    </div>
  );
}

export default Dashboard;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Address",
    target: "deliveryTo.address",
    render: (v) => <p style={{ paddingRight: 10, marginBottom: 10 }}>{v}</p>,
  },
  //   {
  //     title: "Customer Name",
  //     target: "customerInformation.name",
  //   },
  {
    title: "Customer Phone",
    target: "customerInformation.phoneNumber",
    render: (p) => <a href={`tel:${p}`}>{p}</a>,
  },
  {
    title: "Order ID",
    target: "id",
  },
  {
    title: "Status",
    target: "status",
    render: (value) => (
      <span className={`status ${value}`}>
        {value.toString().toUpperCase()}
      </span>
    ),
  },
  {
    title: "Payment",
    target: "paymentMethod",
  },
  {
    title: "Paid",
    target: "paymentCompleted",
    render: (value) => (
      <span className={`status ${value}`}>
        {value.toString().toUpperCase()}
      </span>
    ),
  },

  //   {
  //     title: "Delivery To",
  //     target: "deliveryTo",
  //     render: (v) => (
  //       <a
  //         href={`https://www.google.com/maps/?q=${v.lat},${v.lng}`}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         {v.address}
  //       </a>
  //     ),
  //   },

  //   {
  //     title: "Payment",

  //     // render: (items) =>
  //     //   items
  //     //     .map(
  //     //       (i, idx) =>
  //     //         `${capitalizeFirstLetter(i.item.name)} x${
  //     //           i.quantity
  //     //         } ${currencyFormatter(i.item.price * i.quantity)}, `
  //     //     )
  //     //     .join(", "),
  //   },

  //   {
  //     title: "Selling Price",
  //     target: "priceCapture",
  //     render: currencyFormatter,
  //   },
  {
    title: "Date",
    target: "createdAt",
    render: formatDate,
  },
];

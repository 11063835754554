import { useState } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";

function useMessages() {
  const [messages, setMessages] = useState([]);
  const { get, isLoading } = useAPI();

  const getMessages = async (pageNumber = 1, limit = 20) => {
    const { error, data } = await get(
      urls.admin.messages.baseUrl
        .replace("{{pageNumber}}", pageNumber)
        .replace("{{limit}}", limit)
    );
    if (error) return;
    setMessages(data.data.messages);
  };

  return {
    isLoading,
    getMessages,
    messages,
  };
}

export default useMessages;

import React from "react";
import C404 from "../../components/404";
import useQuery from "../../hooks/useQuery";
import { models } from "../../utils/vars";
import TransactionsList from "../../components/wallets/list/TransactionsList";
import WalletsList from "../../components/wallets/list/WalletsList";

export default function List() {
  const query = useQuery();
  const type = query.get("type");
  if (!type || !models[type]) return <C404 />;

  return type === models.transaction ? <TransactionsList /> : <WalletsList />;
}

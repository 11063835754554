import React from "react";
import { useLocation } from "react-router-dom";

import useBusinesses from "../../../hooks/api/useBusinesses";

import { Form, FormInput, Submit, FormSelect } from "../../form";
import CheckBox from "../../CheckBox";
import { currencyFormatter, getImageUrl } from "../../../utils/func";
import { walletStatus } from "../../../utils/vars";

export default function ViewTransaction() {
  const {
    state: { data },
  } = useLocation();
  const { isLoading, changeWalletStatus } = useBusinesses();

  return (
    <div>
      <h2>View Wallet</h2>
      <br />
      <div className="card-container justify-center">
        <label>Wallet Balance:</label>
        <h1 className="t-blue p-20">{currencyFormatter(data.balance)}</h1>
        <div className="user-profile-head flex align-center">
          <img
            crossOrigin="anonymous"
            src={
              data.account.profileImage
                ? getImageUrl(data.user.profileImage)
                : "https://thumbs.dreamstime.com/z/default-avatar-profile-icon-social-media-user-vector-default-avatar-profile-icon-social-media-user-vector-portrait-176194876.jpg?w=768"
            }
            style={{ height: 100, borderRadius: 50, marginRight: 20 }}
            alt={data.account.firstName}
          />
          <div>
            <h3 className="t-blue">{`${data.account.firstName} ${data.account.lastName} `}</h3>
            <span className="t-grey-3 f500">
              {data.account.email}
              <br />
              {data.account.phoneNumber}
            </span>
            <br />
            <span className={`status ${data.status}`}>
              {data.status == "active" ? "ACTIVE" : data.status.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
      <Form
        onSubmit={(v) => {
          changeWalletStatus({ status: v.status }, data._id);
        }}
        initialValues={{
          balance: data.balance,
          status: data.status,
        }}
      >
        <br />
        <div className="inputs">
          <FormSelect
            name={"status"}
            options={Object.values(walletStatus).map((t) => ({
              value: t,
              label: t.toUpperCase(),
            }))}
            initialValue={data.status}
            placeholder="Select Status"
          />
        </div>
        <div className="flex">
          {/* <label>Active</label> */}
          {/* <CheckBox onChange={setIsActive} value={isActive} /> */}
          {/* <br /> */}
          {/* <br /> */}
        </div>
        <Submit
          loading={isLoading}
          disabled={isLoading}
          className="btn-submit"
          title="Save and Update"
        />
      </Form>
    </div>
  );
}

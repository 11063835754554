import React from "react";
import C404 from "../../components/404";
import useQuery from "../../hooks/useQuery";
import { models } from "../../utils/vars";
import ViewWallet from "../../components/wallets/view/ViewWallet";
import ViewTransaction from "../../components/wallets/view/ViewTransaction";

export default function View() {
  const query = useQuery();

  const type = query.get("type");
  if (!type || !models[type]) return <C404 />;
  return type === models.wallet ? <ViewWallet /> : <ViewTransaction />;
}
